import { BaseModel } from './base.model';

export class ProductAttributesModel extends BaseModel {
  ManufacturerValueId: number;
  Manufacturer: string;
  ManufacturerDescription: number;
  OriginValueId: number;
  Origin: string;
  StrengthValueId: number;
  Strength: string;
  WrapperValueId: number;
  Wrapper: string;
  WrapperColorValueId: number;
  WrapperColor: string;
  WrapperColorDescription: number;
  BinderValueId: number;
  Binder: string;
  BinderDescription: string;
  FillerValueId: number;
  Filler: string;
  FillerDescription: number;
  IsSpecific: boolean;
  Length: string;
  LengthDescription: string | null;
  MasterLine: string;
  RingGauge: number;
  Section: string;
  Shape: string;
  ShapeDescription: number;
  SinglePackaging: string;
  RollingType: string | null;
  RollingTypeDescription: string | null;
}
