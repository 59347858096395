import _isNumber from 'lodash-es/isNumber';
import _toNumber from 'lodash-es/toNumber';
import _isString from 'lodash-es/isString';
import _min from 'lodash-es/min';

import { BaseModel } from './base.model';
import { ProductAttributeValueModel } from './product-attribute-value.model';
import { ProductAttributesModel } from './product-attributes.model';
import { ProductImageModel } from './product-image.model';
import { ProductMyCigarFeaturesModel } from './product-my-cigar-features.model';
import { ProductNoteModel } from './product-note.model';
import { ProductPricesModel } from './product-prices.model';
import { ProductRatingModel } from './product-rating.model';
import { ProductReviewModel } from './product-review.model';
import { ProductTagModel } from './product-tag.model';
import { ICigar } from '@models';

export const PRODUCT_TYPE = 'products';
export const LINE_TYPE = 'lines';

export interface IAux {
  ShippingDate: string;
  ShippingCutOffTime: string;
  Availabilities?: Availability[];
  RatingSummaries: RatingSummary[];
}

interface RatingSummary {
  ProductId: number;
  LineId: number;
  DrawRating: number;
  AppearanceRating: number;
  BurnRating: number;
  AromaRating: number;
  TasteRating: number;
}

interface Availability {
  ProductId: number;
  AvailablePcs: number;
}
export interface INeptunePrices {
  NeptuneSinglePriceMin: number;
  NeptuneSinglePriceMax: number;
  NeptuneBoxPriceMin: number;
  NeptuneBoxPriceMax: number;
}

/**
 * @deprecated use ngrx model
 */
export class ProductModel extends BaseModel {
  protected _baseData;
  constructor(data: Object) {
    super(data);
    this._baseData = data;
    this.setId();
  }

  static nestedModels = {
    Shapes: ProductModel,
    RatingSummary: ProductRatingModel,
    Prices: ProductPricesModel,
    PartnerPrices: ProductPricesModel,
    Tags: ProductTagModel,
    Attributes: ProductAttributesModel,
    Images: ProductImageModel,
    MyNote: ProductNoteModel,
    MyRating: ProductReviewModel,
  };

  static mappings = {
    Rating: 'RatingSummary',
    UserRating: 'MyRating',
  };

  Id: string;
  ProductId: number;
  LineId: number;
  Date: string;
  Name: string;
  ImageUrl: string;
  ImageUrlSmall: string;
  ImageOfSingleUrl: string;
  ImageOfSingleWidth?: number;
  ImageOfSingleHeight?: number;
  UserImageUrl: string;
  RatingSummary: ProductRatingModel;
  Prices: ProductPricesModel;
  Aux: IAux;
  NeptunePrices: INeptunePrices;
  PartnerPrices: ProductPricesModel;
  Shapes: ProductModel[];
  Attributes: ProductAttributesModel;
  BandHistory: any[];
  Description: string;
  Images: ProductImageModel[];
  MaxBoxQty: number;
  MinBoxQty: number;
  MyCigarFeatures: ProductMyCigarFeaturesModel;
  MyNote: ProductNoteModel;
  MyRating: ProductReviewModel;
  Tags: ProductTagModel[];
  AdUrl: string;
  CanonicalUrl: string;
  IsCustom: boolean;
  SocialPosts: number;
  Location: string;
  AdditionalAttributes: ProductAttributeValueModel[];

  static decomposeId(id: string) {
    const idParts = id.split('-');
    let type = LINE_TYPE;

    if ('P' === idParts[0]) {
      type = PRODUCT_TYPE;
    }

    return {
      type: type,
      id: Number(idParts[1]),
    };
  }

  getType() {
    if (this.ProductId) {
      return PRODUCT_TYPE;
    } else {
      return LINE_TYPE;
    }
  }

  bestPrices(): ProductPricesModel {
    return new ProductPricesModel({
      SinglePriceMin: this.bestSinglePrice(),
      BoxPriceMin: this.bestBoxPrice(),
    });
  }

  bestSinglePrice() {
    const prices = [];

    if (this.Prices && this.Prices.SinglePriceMin > 0) {
      prices.push(this.Prices.SinglePriceMin);
    }

    if (this.PartnerPrices && this.PartnerPrices.SinglePriceMin > 0) {
      prices.push(this.PartnerPrices.SinglePriceMin);
    }
    return _min(prices);
  }

  bestBoxPrice() {
    const prices = [];

    if (this.Prices && this.Prices.BoxPriceMin > 0) {
      prices.push(this.Prices.BoxPriceMin);
    }

    if (this.PartnerPrices && this.PartnerPrices.BoxPriceMin > 0) {
      prices.push(this.PartnerPrices.BoxPriceMin);
    }
    return _min(prices);
  }

  private setId() {
    if (_isString(this.Id)) {
      return;
    } else if (_isNumber(this.Id)) {
      // TODO: When Id on shapes api resource is renamed to productId this check should be removed
      this.ProductId = _toNumber(this.Id);
    }

    if (this.ProductId) {
      this.Id = `P-${this.ProductId}`;
    } else {
      this.Id = `L-${this.LineId}`;
    }
  }

  getNewInterface(): ICigar {
    return this._baseData;
  }
}
